import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useLocation } from "@reach/router"

import Benefits from "../../components/pharmacy/Benefits"
import Contact from "../../components/pharmacy/Contact"
import Header from "../../components/pharmacy/Header"

import { Helmet } from "react-helmet"

import { deCH } from "../../lang/de-CH"

export default function Apotheken() {
  const location = useLocation()

  let country = "CH"
  let lang = "de-CH"
  let txt = deCH.Pharmacy

  return (
    <Layout location={country} path={location.pathname} language={lang}>
      <Seo
        title="DoryGo - Unser Angebot für Apotheken"
        description="Informationen für Apotheken"
        lang={lang}
      />
      <Helmet>
        <link
          rel="alternate"
          hreflang="de-DE"
          href="https://dorygo.com/de-de/apotheken"
        />
        <link
          rel="canonical"
          hreflang="de-CH"
          href="https://dorygo.ch/ch-de/apotheken"
        />
      </Helmet>

      <Header language={lang}/>
      <Benefits txt={txt.Benefits} country={country} />
      <Contact txt={txt.Contact} />
    </Layout>
  )
}
